<template>
  <div class="collecPostList">
    <PullRefresh
      :loading="loading"
      :refreshing="refreshing"
      :finished="finished"
      @onLoad="onLoad"
      @onRefresh="onRefresh"
      :isHigehtMax="true"
      :isNoData="isNoData"
      :error="error"
    >
      <div class="postBox" v-for="(item, index) in list" :key="item.id">
        <div class="selectBox" @click.stop="selectItem(item)" :class="{ activeSelectBox: isEdit }">
          <svg-icon class="select" iconClass="batchSelected" v-if="item.select" />
          <svg-icon class="select" iconClass="batchUnSelect" v-else />
        </div>
        <div class="main" :class="{ activeMain: isEdit }">
          <Post class="postItem" :postInfo="item" :index="index" />
        </div>
      </div>
    </PullRefresh>
  </div>
</template>

<script>
import { Toast } from 'vant';
import Post from '@/components/Post';
import PullRefresh from '@/components/PullRefresh';
import { queryCollecList } from '@/api/mine';
import { careCollectVideo } from '@/api/user';
import { getSessionItem } from '@/utils/longStorage';

export default {
  name: 'CollecPostList',
  components: {
    Post,
    PullRefresh,
  },
  data() {
    return {
      isEdit: false,
      list: [],
      pageNumber: 1, // 页码
      pageSize: 10, // 条数
      loading: true, // loading
      refreshing: false, // 刷新状态
      finished: false, // 下拉状态
      error: false, // 是否显示错误信息
      isNoData: false, // 暂无数据
    };
  },
  computed: {
    isSuccess() {
      return this.$store.state.user.updatecollecPostList.isSuccess;
    },
  },
  watch: {
    isSuccess: function (val) {
      if (!val) return;
      this.$store.commit('user/UPDATE_Collec_Graphics', {
        isSuccess: false,
        id: '',
      });
      let index = this.$store.state.user.commentData.index;
      let id = this.$store.state.user.updatecollecPostList.id;
      //先通过评论索引去修改
      if (index >= 0) {
        this.list[index].commentCount++;
        return;
      }
      //如果评论索引没找到，通过id去修改
      if (id) {
        let fIndex = this.list.findIndex((el) => el.id === id);
        this.list[index].commentCount++;
        return;
      }
    },
  },
  created() {
    this.getList();
  },
  methods: {
    // 修改编辑状态
    changeEditSta(isEdit) {
      this.isEdit = isEdit;
    },
    // 选择批量删除的数据
    selectItem(work) {
      this.list.map((item) => (item.id == work.id ? this.$set(item, 'select', !item.select) : ''));
    },
    // 选择全部
    selectAll(isSelectAll) {
      this.list.map((item) => this.$set(item, 'select', isSelectAll));
    },
    // 批量删除
    batchDel() {
      const delArr = this.list.map((item) => (item.select ? item.id : undefined)).filter((item) => item);
      if (delArr.length == 0) return Toast('至少选择一项进行删除');
      this.del(delArr);
    },
    // 查询列表
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        type: 'img', // 影视-movie 图文-img 视频-video 话题-tag
        uid: JSON.parse(getSessionItem('userInfo')).uid,
      };
      try {
        let res = await this.$Api(queryCollecList, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (type === 'refresh') {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          // this.isNoData = this.comList.length > 0 ? false : true;
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },
    // 上拉加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList('refresh');
    },
    // 取消收藏
    async del(item) {
      let req = {
        type: 'img',
        objIds: item,
        isCollect: false,
      };
      this.$store.commit('app/SET_LOADING', true);
      try {
        let res = await this.$Api(careCollectVideo, req);
        this.$store.commit('app/SET_LOADING', false);
        if (res.code === 200) {
          // this.list = this.list.filter((i) => {
          //   return i.id != info.id;
          // });
          // if (this.list.length <= 0) {
          //   this.isNoData = true;
          // }
          this.pageNumber = 1;
          this.loading = true;
          this.getList('refresh');
          Toast('删除成功');
          return;
        }
        Toast('操作失败');
      } catch (e) {
        this.$store.commit('app/SET_LOADING', false);
        Toast('操作失败');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.collecPostList {
  width: 100%;
  height: calc(100% - 92px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  .postBox {
    margin-bottom: 10px;
    display: flex;
    width: 100%;
    .selectBox {
      padding: 0 11px;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateX(-44px);
      transition: transform 0.3s;
      .select {
        height: 22px;
        width: 22px;
        border-radius: 50%;
      }
    }
    .main {
      width: 100%;
      background-color: #fff;
      padding: 16px 0;
      transform: translateX(-44px);
      transition: transform 0.3s;
      .postItem {
        width: 350px;
        margin: 0 auto;
        padding: 0px 16px;
      }
    }
    .activeSelectBox {
      transform: translateX(0px);
      transition: transform 0.3s;
    }
    .activeMain {
      transform: translateX(0px);
      transition: transform 0.3s;
    }
  }
}
</style>
